import moment from "moment"
import { surveyCap, surveyFilters } from "@/apis"

/**
 * format survay cap options data
 */
const ordinal_suffix_of = (i) => {
  var j = i % 10,
    k = i % 100
  if (j === 1 && k !== 11) {
    return i + "st Cap"
  }
  if (j === 2 && k !== 12) {
    return i + "nd Cap"
  }
  if (j === 3 && k !== 13) {
    return i + "rd Cap"
  }
  return i + "th Cap"
}

export const fetchSurveyCap = async (queryData) => {
  const { data } = await surveyCap(queryData)
  if (data.surveyCap.length) {
    const formatedArray = data.surveyCap
      .map((el, index) => {
        const cap = ordinal_suffix_of(index)
        return `${index === 0 ? "Current Cap" : cap} (${el.startDate}-${
          el.endDate
        }) `
      })
      .reverse()
    const remEl = formatedArray.pop()
    formatedArray.unshift(remEl)

    return { apiData: data, formatedData: formatedArray }
  } else {
    return { apiData: [], formatedData: [] }
  }
}

export const usersnap = {
  add: (user) => {
    const key = process.env.REACT_APP_USERSNAP_KEY
    if (!window.Usersnap && key) {
      window.onUsersnapCXLoad = function (api) {
        api.init({
          user: {
            userId: user.id,
            email: user.email,
          },
        })

        window.Usersnap = api
      }

      const script = document.createElement("script")
      script.classList.add("usersnap-script")
      script.defer = 1
      script.src = `https://widget.usersnap.com/global/load/${key}?onload=onUsersnapCXLoad`
      document.getElementsByTagName("head")[0].appendChild(script)
    }
  },
  remove: () => {
    if (window.Usersnap) {
      window.Usersnap.destroy()
    }

    const script = document.querySelector(".usersnap-script")
    if (script) {
      script.remove()
    }
  },
}

export const formatDatesForQuery = (finalQuery) => {
  if (finalQuery.startDate || finalQuery.endDate) {
    finalQuery.startDate = moment(finalQuery.startDate).format().split("T")[0]
    finalQuery.endDate = moment(finalQuery.endDate).format().split("T")[0]
    return finalQuery
  }
  return finalQuery
}

export const formatQuery = ({
  _id,
  name,
  startDate,
  endDate,
  projectId,
  active,
  order,
  projectSurveys,
  management,
  projectClientCode,
  selectedProject,
  selectedSurveyProducts,
}) => {
  const obj = {
    projectboardId: _id,
    name,
    option: "7d",
    startDate,
    endDate,
    projectId,
    active,
    order,
    projectSurveys,
    management,
    projectClientCode,
    selectedProject,
    selectedSurveyProducts,
  }
  return obj
}

export const getSurveyFiltersData = async (survey) => {
  try {
    const {
      data: { data },
    } = await surveyFilters(survey) //testing no filters 6303532d00cfd507c4039a26
    return data
  } catch ({ errorCode }) {
    return errorCode
  }
}

export const handleSelectedFilters = ({
  ele, // selected label {obj}
  item, // select question {obj}
  selectedFilters,
  index,
  indexLabel,
  currentSurveyId,
  addStates = () => {},
}) => {
  //current selected filters
  let data = selectedFilters ? JSON.parse(JSON.stringify(selectedFilters)) : {}
  const { _id, enablePostalCodes } = item //question id

  const surveyIdIndex = `${currentSurveyId}-${index}`

  let labelVal = ele
  if (enablePostalCodes) {
    //edit selected label value
    labelVal = { label: ele.label, value: ele.value, zipCodes: [] }
  }

  if (item.labels[indexLabel].matrixId) {
    labelVal = {
      label: ele.label,
      value: ele.value,
      parentLabel: item.labels[indexLabel].parentLabel,
      matrixId: item.labels[indexLabel].matrixId,
    }
  }

  if (!Object.keys(data).length) {
    //empty filters
    const obj = {
      title: item.title,
      surveyId: currentSurveyId,
      typeofQuestion: item.typeOfQuestion,
      questionId: item._id,
      labels: [labelVal],
    }
    data = {
      ...data,
      ...{ [surveyIdIndex]: { [_id]: obj } },
    }

    if (enablePostalCodes) {
      //add states & zipCodes
      addStates(ele)
    }

    return data
  }

  if (!data[surveyIdIndex]) {
    //new question is added to filter
    const obj = {
      title: item.title,
      surveyId: currentSurveyId,
      typeofQuestion: item.typeOfQuestion,
      questionId: item._id,
      labels: [labelVal],
    }
    data = {
      ...data,
      ...{ [surveyIdIndex]: { [_id]: obj } },
    }

    if (enablePostalCodes) {
      //add states & zipCodes
      addStates(ele)
    }
    return data
  }

  if (!data[surveyIdIndex][_id]) {
    //new filter is added to question
    const obj = {
      title: item.title,
      surveyId: currentSurveyId,
      typeofQuestion: item.typeOfQuestion,
      questionId: item._id,
      labels: [labelVal],
    }

    let previousData = {
      ...data[surveyIdIndex],
      ...{ [_id]: obj },
    }

    data = {
      ...data,
      ...{ [surveyIdIndex]: previousData },
    }

    if (enablePostalCodes) {
      //add states & zipCodes
      addStates(ele)
    }

    return data
  }

  // question and filters already existed
  const existingFilter = data[surveyIdIndex][_id].labels
  const findFilter = existingFilter.find((item) => {
    if (item.matrixId) {
      return (
        item.matrixId === labelVal.matrixId && item.label === labelVal.label
      )
    }
    return item.label === labelVal.label
  })

  if (findFilter) {
    if (enablePostalCodes) {
      //add states & zipCodes
      addStates(findFilter)
    }

    const filtered = existingFilter.filter((item) => {
      if (item.matrixId) {
        return (
          item.matrixId !== labelVal.matrixId || item.label !== labelVal.label
        )
      }

      return item.label !== labelVal.label
    })
    data[surveyIdIndex][_id].labels = filtered //update labels

    if (!filtered.length) {
      // remove question from filters if deselectall
      delete data[surveyIdIndex][_id]

      // remove survey from filters if no question is selected
      if (!Object.keys(data[surveyIdIndex]).length) delete data[surveyIdIndex]
    }
  } else {
    data[surveyIdIndex][_id].labels = [
      ...data[surveyIdIndex][_id].labels,
      labelVal,
    ]

    if (enablePostalCodes) {
      //add states & zipCodes
      addStates(ele)
    }
  }

  return data
}

export const formatLabels = (filters, survey, index) => {
  let mapped = []
  if (filters) {
    const data = filters[`${survey + "-" + index}`]

    for (const key in data) {
      const obj = {
        key: data[key].title,
        value: data[key].labels.map((label) => label.label),
      }
      mapped.push(obj)
    }
    return mapped
  } else {
    return mapped
  }
}

export const updateChartName = (management, id, index, setName) => {
  let managementCopy = JSON.parse(JSON.stringify(management))
  managementCopy.forEach((survey, surveyIndex) => {
    if (survey.id === id && surveyIndex === index) {
      survey.name = setName
    }
  })
  return managementCopy
}

export const helperDataUpdateAtIndex = async (
  statsData,
  graphData,
  statsCopyData,
  graphscopyData,
  index,
  id
) => {
  const surveyIndex = statsCopyData.findIndex(
    (survey, surveyindex) => survey.id === id && surveyindex === index
  )
  if (surveyIndex !== -1) {
    statsCopyData[surveyIndex] = statsData
    graphscopyData[surveyIndex] = { id: id, data: graphData }

    return { statsCopyData, graphscopyData }
  }
  return { statsCopyData, graphscopyData }
}
